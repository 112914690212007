.login {

	width: 100vw;

	// .login__wrapper

	&__wrapper {
		position: relative;
		min-height: 100svh;

		display: flex;
		flex-direction: column;
		@include _992 {
			flex-direction: row;
		}
	}

	// .login__col

	&__col {
		&:nth-child(1) {
			
			padding: 15px 15px 30px;

			@include _992 {
				padding: 15px;
				max-width: calc(100vw - (((100vw - 1720px) / 2) + ((1720px - var(--container-padding) * 2) * 0.35) + var(--container-padding)));

				padding-top: 100px; padding-bottom: 50px; padding-right: 100px;
				padding-left: max(100px, calc(50% - 860px));

				flex: none;
			}

			flex: 1 1;

			display: flex;
			flex-direction: column;
			justify-content: space-between;

			width: 100%;

			position: relative;
			z-index: 2;

		}

		&:nth-child(2) {
			position: relative;

			@include _992 {
				position: absolute;
				right: 0; top: 0;
				width: calc(100vw - (((100vw - 1720px) / 2) + ((1720px - var(--container-padding) * 2) * 0.6475) + var(--container-padding)));
				height: 100%;
			}

			&::after {
				content: '';
				position: absolute;
				left: 0; top: 0;
				width: 100%; height: 100%;
				background: var(--accent);
				opacity: 1;
				mix-blend-mode: color;
			}
		}
	}

	// .login__header

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	// .login__logo

	&__logo {
		img {
			width: 70px; height: 70px;
			object-fit: contain;
			@include _992 {
				height: 100px; width: 100px;
			}
		}
	}

	// .login__lang

	&__lang {

		// .login__lang--target

		&--target {
			background: transparent;

			display: flex;
			align-items: center;
			justify-content: flex-end;
			text-transform: uppercase;

			color: var(--title-color);
		}

		// .login__lang--target-arrow

		&--target-arrow {
			flex: 0 0 10px;
			margin-left: 6px;
		}

		// .login__lang--block

		&--block {
			min-width: 70px;
		}

		// .login__lang--list

		&--list {
		}
	}

	// .login__main

	&__main {
		padding: 30px 0 30px;
		@include _992 {
			padding: 30px 0 70px;
		}

		max-width: 600px;
		margin: 0 auto;
		width: 100%;

		
	}

	// .login__form

	&__form {
		display: grid;
		gap: 10px;
	}

	// .login__title

	&__title {
		@include _992 {
			margin-bottom: 20px;
		}
	}

	// .login__drop-down

	&__drop-down {

		// .login__drop-down--target

		&--target {
			
		}

		// .login__drop-down--arrow

		&--arrow {
		}

		// .login__drop-down--block

		&--block {
			//width: 75px;
		}

		// .login__drop-down--list

		&--list {
		}
	}

	// .login__label

	&__label {
		
		display: block;
		position: relative;
		width: 100%;

		input {
			width: 100%;
		}
	}

	// .login__input

	&__input {
	}

	// .login__show-password

	&__show-password {
	}

	// .login__checkbox

	&__checkbox {
		
	}

	// .login__form-row

	&__form-row {
		display: grid;
		@include _550 {
			grid-template-columns: 1fr 1fr;
		}
		gap: 10px;

		&:last-child {
			margin-top: 10px;
		}
	}

	// .login__submit

	&__submit {
	}

	// .login__button

	&__button {
	}

	// .login__footer

	&__footer {
		display: flex;
		align-items: center;
		flex-direction: column;

		text-align: center;

		@include _992 {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;

			text-align: start;
		}

		gap: 20px;
	}

	// .login__privacy

	&__privacy {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@include _992 {
			gap: 20px 40px;
			justify-content: stretch;
		}

		gap: 20px;

		a {
			color: var(--text-color);
		}
	}

	// .login__copyright

	&__copyright {
		color: rgba(94, 100, 115, 0.5);

		font-size: 0.75rem;

		@include _992 {
			font-size: 0.875rem;
		}
	}

	// .login__image

	&__image {

		width: 100%; padding-top: 80%;
		position: relative;

		overflow: hidden;

		@include _992 {
			width: 100%; height: 100%;
			padding-top: 0;
			overflow: hidden;
		}

		display: flex;
		align-items: center;

		img {

			object-fit: cover;

			position: absolute;
			left: 50%; top: 10%;
			transform: translate(-50%,5%);
			width: 150%; height: auto;

			@include _992 {
				width: 130%; height: auto;
				top: 50%;
				transform: translate(-50%,-20%);
			}
		}	
	}
}


.down-sidebar {

	position: fixed;
	left: 0; bottom: 0;
	width: 100vw;
	@include _992 {
		left: var(--aside-width, 230px); bottom: 0;
		width: calc(100% - var(--aside-width, 230px));

		transition: var(--transition-width, none);
	}

	z-index: 2;

	background: var(--white);

	&__wrapper {
		display: grid;

		max-height: calc((var(--vh,1vh) * 100) - 60px - 40px);
		overflow-y: auto;

		padding: 20px;
		@media (min-width: 1440px) {
			padding: 20px 50px 20px 50px;
		}
	}
	
	box-shadow: 0px -10px 20px 0px rgba(63, 70, 94, 0.10);

	&.is-active & {
		&__arrow-target {
			svg {
				transform: scaleY(1);
			}
		}

		&__target-place {
			opacity: 0; visibility: hidden;
		}

		/* &__row {
			display: flex;
			@include _992 {
				display: grid;
			}
		} */
	}

	// .down-sidebar__arrow-target

	&__arrow-target {
		position: absolute;
		left: 50%; top: 0;
		transform: translate(-50%,-50%);
		z-index: 2;

		width: 45px; height: 25px;
		background: var(--white);
		color: #AEB6CE;

		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			transform: scaleY(-1);

			transition: transform .3s ease;
		}
	}

	// .down-sidebar__target-place

	&__target-place {

		grid-area: 1/1;

		display: block;

		opacity: var(--opacity, 1);
		align-self: self-end;
		//transition: opacity .3s ease, visibility .3s ease;

		// .down-sidebar__target-place--row

		&--row {
			display: flex;
			flex-direction: column;

			@include _992 {
				align-items: center;
				justify-content: space-between;
				flex-direction: row;
			}
		}
	}

	// .down-sidebar__comments-target

	&__comments-target {
		white-space: nowrap;

		svg {
			margin-right: 10px;
		}
	}

	// .down-sidebar__total-target

	&__total-target {

		display: none;
		@include _992 {
			display: flex;
		}

		align-items: center;

		// .down-sidebar__total-target--value

		&--value {
			margin-left: 10px;

			font-size: 0.75rem;
			@include _992 {
				font-size: 0.875rem;
			}
			line-height: 100%;
			color: var(--theme-text-color-2);

			padding: 15px 20px;
			border: 1px solid rgba(174, 182, 206, 0.30);
		}
	}

	&.is-active &__row {
		grid-template-rows: 1fr;

		> * {
			padding: 2px 0;
		}
	}

	// .down-sidebar__row

	&__row {
		opacity: var(--opacity, 1);
		grid-area: 1/1;
		align-self: self-end;

		display: grid;
		grid-template-rows: 0fr;

		transition: grid-template-rows .5s ease;

		> * {
			overflow: hidden;
			transition: padding .3s ease;
			padding: 0;

			display: grid;
			> *:nth-child(1) {
				grid-column: 1; grid-row: 2;
				//width: calc(100% - 0px);
				width: calc(100vw - 40px);
			}
			> *:nth-child(2) {
				grid-column: 1; grid-row: 1;
				width: calc(100vw - 40px);
			}

			gap: 20px;
		}

		@include _768 {
			
			> * {
				display: grid;
				grid-template-columns: calc(50% - 10px) calc(50% - 10px);

				> *:nth-child(1) {
					grid-column: auto; grid-row: auto;
					width: 100%;
				}
				> *:nth-child(2) {
					grid-column: auto; grid-row: auto;
					width: 100%;
				}
			}
		}
	}

	// .down-sidebar__col

	&__col {
	}

	// .down-sidebar__chat

	&__chat {
	}

	// .down-sidebar__chat-block

	&__chat-block {
		height: calc(var(--vh,1vh) * 25);
		@include _992 {
			height: 163px;
		}

		padding: 10px 15px;
		//overflow-y: auto;

		&--none {
			color: var(--text-color);
			font-size: 0.875rem;
			line-height: 120%;

			padding: 10px 5px;
		}

		border: 1px solid rgba(174, 182, 206, 0.30);

		.simplebar-track.simplebar-vertical {
			width: 5px;
			margin: 10px 5px;
			height: calc(100% - 20px);
		}

		.simplebar-scrollbar::before {
			top: 0; left: 0; right: 0; bottom: 0;
			border-radius: 0;
			background: #5F6472;
			opacity: .1;
		}
	}

	// .down-sidebar__chat-item

	&__chat-item {

		padding: 12px 17px;
		background: rgba(174, 182, 206, 0.15);

		display: flex;
		align-items: center;
		
		&:not(:first-child) {
			margin-top: 10px;
		}

		// .down-sidebar__chat-item--text

		&--text {
			padding-right: 10px;

			flex: 1 1 auto;

			font-size: 0.75rem;
			@include _992 {
				font-size: 0.875rem;
			}
			line-height: 140%;
			color: var(--heading-color);

			span {
				color: #97A0BB;
			}

			b {
				font-weight: 600;
			}
		}

		// .down-sidebar__chat-item--answer

		&--answer {
			background: transparent;
			color: var(--accent);
		}
	}

	// .down-sidebar__chat-user-panel

	&__chat-user-panel {
		margin-top: 20px;

		display: grid;
		grid-template-columns: auto 45px;

		label {
			width: 100%;
			input {
				width: 100%;
			}
		}

		button {
			background: var(--accent);
			color: var(--theme-text-color);

			display: flex;
			align-items: center;
			justify-content: center;

			@media (any-hover: hover) {
				transition: background .2s ease;

				&:hover {
					background: var(--accent-light);
				}
				
				&:active {
					background: var(--accent-dark);
				}
			}
		}
	}

	// .down-sidebar__total

	&__total {
		display: flex;
		flex-direction: column-reverse;

		@include _768 {
			display: block;
		}
	}

	// .down-sidebar__total-block

	&__total-block {
		height: calc(var(--vh,1vh) * 25);
		@include _992 {
			height: 163px;
		}
		padding: 10px 15px;
		//overflow-y: auto;

		&--none {
			color: var(--text-color);
			font-size: 0.875rem;
			line-height: 120%;

			padding: 10px 5px;
		}

		border: 1px solid rgba(174, 182, 206, 0.30);

		.simplebar-track.simplebar-vertical {
			width: 5px;
			margin: 10px 5px;
			height: calc(100% - 20px);
		}

		.simplebar-scrollbar::before {
			top: 0; left: 0; right: 0; bottom: 0;
			border-radius: 0;
			background: #5F6472;
			opacity: .1;
		}
	}

	// .down-sidebar__total-item

	&__total-item {

		display: flex;
		flex-direction: column;

		@media (min-width: 1440px) {
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
		}

		gap: 10px 20px;

		&:not(:first-child) {
			margin-top: 12px;
		}

		// .down-sidebar__total-item--name

		&--name {
			color: var(--text-color);
			font-size: 0.75rem;
			@include _992 {
				font-size: 0.875rem;
			}
			font-weight: 600;
			line-height: 140%;
		}

		// .down-sidebar__total-item--value

		&--value {
			display: flex;
			flex: 1 1 auto;

			b {
				color: var(--text-color);
				font-size: 0.75rem;
				@include _992 {
					font-size: 0.875rem;
				}
				font-weight: 600;
				line-height: 140%;

				padding-right: 12px;
			}
		}

		// .down-sidebar__total-item--progress-bar

		&--progress-bar {
			height: 20px; flex: 1 1 auto;
			background: rgba(174, 182, 206, 0.30);

			position: relative;
			&::before {
				content: '';
				position: absolute;
				left: 0; top: 0;
				width: var(--value,0%); height: 100%;
				background: rgba(151,160,187,0.5);
			}

			&::after {
				content: attr(data-value);
				position: absolute;
				left: 50%; top: 50%;
				transform: translate(-50%,-50%);

				color: var(--black);
				font-size: 0.875rem;
				line-height: 100%;
			}
		}
	}

	// .down-sidebar__total-footer

	&__total-footer {

		margin-bottom: 20px;
		
		@include _768 {
			margin-top: 20px; margin-bottom: 0;
			justify-content: flex-end;
		}

		display: flex;
			
	}

	// .down-sidebar__total-value

	&__total-value {
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: space-between;

		@include _768 {
			width: auto;
			justify-content: stretch;
		}

		font-size: 0.875rem;
		line-height: 120%;
		color: var(--text-color);

		b {
			margin-left: 10px;

			border: 1px solid rgba(174, 182, 206, 0.30);
			color: var(--theme-text-color-2);

			display: inline-block;
			padding: 15px 20px;

			font-weight: 400;
		}
	}
}

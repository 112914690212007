
/* @font-face {
	@include font-include('Inter', 400, swap, normal, 'Inter-Regular');
}

@font-face {
	@include font-include('Inter', 500, swap, normal, 'Inter-Medium');
}

@font-face {
	@include font-include('Inter', 600, swap, normal, 'Inter-SemiBold');
}

@font-face {
	@include font-include('Inter', 800, swap, normal, 'Inter-ExtraBold');
} */

.main-title {
	font-family: var(--title-font);
	font-weight: 700;
	line-height: 115%;

	font-size: 1.875rem;

	@include _768 {
		font-size: 2.5rem;
	}

	@include _992 {
		font-size: 3.125rem;
	}
}

.title {
	
	font-family: var(--title-font);
	font-weight: 800;
	line-height: 120%;
	&.is-center {
		text-align: center;
	}

	&.is-large {
		font-size: 1.5625rem; // 25px
	}

	font-size: 1.375rem; // 22px

	@media (min-width: 360px) {
		font-size: 1.5625rem; // 25px
	}

	@include _992 {
		
		font-size: 1.875rem; // 30px

		&.is-large {
			font-size: 3.125rem; // 50px
		}

	}

}

.large-title {
	font-weight: 800;
	line-height: 120%;
	color: var(--title-color);

	font-size: 1.5625rem;

	@include _550 {
		font-size: 2.25rem;
	}
	
	@include _768 {
		font-size: 2.75rem;
	}
	
	@include _992 {
		font-size: 3.125rem;
	}
}

.section-title {

	font-family: var(--title-font);
	font-weight: 700;

}

.txt-center {
	text-align: center;
}

.popup-page {

	min-height: 100vh;

	height: calc(var(--vh,1vh) * 100);

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 16px;

	// .popup-page__body

	&__body {
		filter: drop-shadow(0px 4px 5px rgba(65, 79, 124, 0.10)) drop-shadow(0px 0px 30px rgba(65, 79, 124, 0.08));

		max-width: 680px; min-height: 305px;
		width: 100%;

		padding: 20px 20px 40px;

		@include _550 {
			padding: 40px;
			min-height: 375px;
		}

		background: var(--white);

		display: flex;
		flex-direction: column;
		justify-content: center;

		&--wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			min-height: 100%;

			text-align: center;
		}
	}

	&__text {
		margin-top: 20px;

		color: var(--text-color);
		font-size: 0.875rem;
		line-height: 140%;
	}

	&__footer {
		margin-top: 30px;

		width: 100%;
		display: flex;
		justify-content: center;
	}

	&__button {
		max-width: 300px; width: 100%;
	}

	// .popup-page__header

	&__header {
		display: flex;
		justify-content: flex-end
	}

	// .popup-page__lang

	&__lang {

		// .popup-page__lang--target

		&--target {
			background: transparent;

			font-size: 0.875rem;
			line-height: 100%;
			color: #5F6472;
			text-transform: uppercase;

			display: inline-flex;
			align-items: center;

			transition: color .2s ease;

			span {
				padding-right: 10px;
			}

			svg {
				
			}

			&.is-active svg {
				color: var(--accent);
			}
		}

		// .popup-page__lang--block

		&--block {
			
		}
	}

	// .popup-page__title

	&__title {
		text-align: center;
		margin-top: 20px;
		@include _550 {
			text-align: start;
		}
	}

	// .popup-page__block

	&__block {

		margin-top: 30px;

		// .popup-page__block--item

		&--item {
			position: relative;
			display: flex;
			&:not(:first-child) {
				margin-top: 10px;
			}

			&.is-row {
				display: grid;
				
				@include _550 {
					grid-template-columns: 1fr 1fr;
				}

				gap: 10px;
			}

			label {
				width: 100%;
				input {
					width: 100%;
				}
			}
		}
	}

	// .popup-page__submit

	&__submit {
		margin-top: 10px;

		grid-column: auto;
		@include _550 {
			grid-column: 2;
		}
	}
}

.section-table {
  //width: 100%;
  padding: 0 0 calc(var(--down-sidebar-height, 0px) + 50px);
  @include _992 {
    padding: 0 0 calc(var(--down-sidebar-height, 0px) + 30px);
  }

  width: 100%;

  min-height: 90vh;

  // .section-table__title

  &__title {
    > span {
      align-self: center;

      @media screen and (max-width: 576px) {
        span {
          font-size: 12px;
        }
      }
    }
  }

  // .section-table__header

  &__header {
    position: relative;
    z-index: 3;
    //top: -55px;

    &__header > &__header--row:first-child {
      position: relative;
      z-index: 3;
    }

    // .section-table__header--row

    &--row {
      &:not(:first-child) {
        margin-top: 20px;
      }

      display: flex;

      justify-content: space-between;
      flex-direction: column;

      &.is-always-row {
        flex-direction: row;
      }

      &.is-alternative-row {
        @include _992 {
          flex-direction: row;
        }
      }

      &.row-2 {
        flex-wrap: wrap;
        @include _992 {
          flex-direction: row;
        }

        @media (min-width: 1150px) {
          flex-direction: row;
        }
      }

      gap: 10px;

      @media (min-width: 1440px) {
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
      }
    }

    // .section-table__header--cost

    &-cost {
      .section-table__header--col {
        flex-wrap: nowrap;
        @media screen and (max-width: 1200px) {
          &:last-child {
            width: 100%;
            .section-table__export {
              width: 100%;
            }
          }
        }
        @media screen and (max-width: 992px) {
          &:last-child {
            .section-table__export {
              grid-column-start: 1;
              grid-column-end: 3;
            }
          }
        }
      }
    }

    // .section-table__header--col

    &--col {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      position: relative;
      //z-index: 3;
      
      @media screen and (max-width: 992px) {
        & {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
      @media screen and (max-width: 576px) {
        & {
          grid-template-columns: 1fr;
        }
      }

      @media (min-width: 1440px) {
        .section-table__add-task--project {
          width: 240px;
        }
      }

      &:first-child {
        flex: 1 1 auto;
      }

      &:last-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min-content;
        @include _992 {
          display: flex;
        }
      }
    }

    &--block {
      &:not(:first-child) {
        margin-top: 15px;
      }

      display: grid;

      &.is-active > * {
        &:nth-child(1) {
          display: none;
        }

        &:nth-child(2) {
          display: block;
          opacity: var(--opacity, 1);
          visibility: var(--visibility, visible);
        }
      }

      > * {
        grid-area: 1/1;
        background: #f3f4f8;

        //opacity: var(--opacity, 1); visibility: var(--visibility, visible);

        &:nth-child(1) {
          display: block;
          opacity: var(--opacity, 1);
          visibility: var(--visibility, visible);
        }

        &:nth-child(2) {
          display: none;
        }
      }
    }

    // .section-table__header--add-task

    &--add-task {
      margin-top: -1px;

      @media screen and (max-width: 576px) {
        .select button {
          justify-content: space-between;
          padding-left: 13px;
          padding-right: 13px;
        }
        .section-table__time--target {
          padding-left: 18px;
          padding-right: 18px;
        }
      }
      @media screen and (max-width: 992px) {
        .select {
          max-width: 100%;
        }
      }
    }
  }

  &__header--col:nth-child(1) &__add {
    flex: 1 1 auto;
    grid-column-start: 1;
    grid-column-end: 3;
    @include _992 {
      flex: 0 1 auto;
    }
  }

  // .section-table__add-member

  &__add {
    width: auto;
    min-width: 190px;
  }

  // .section-table__search

  &__search {
    @media (min-width: 1440px) {
      max-width: 604px;
      width: 100%;
    }

    &.is-alternative {
      width: 100%;

      display: grid;
      grid-template-columns: auto 47px;
    }

    @include _992 {
      display: grid;
      grid-template-columns: auto 121px;
      &.is-alternative {
        grid-template-columns: auto 121px;
      }
    }

    &.is-active & {
      &--label {
        opacity: 1;
        visibility: visible;
      }
    }

    &.is-alternative &--label {
      //position: static;
      opacity: 1;
      visibility: visible;
      width: auto;

      @include _992 {
        //position: static;
        opacity: 1;
        visibility: visible;
        width: auto;
      }
    }

    // .section-table__search--label

    &--label {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;

      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;

      @include _992 {
        position: static;
        opacity: 1;
        visibility: visible;
        width: auto;
      }
    }

    // .section-table__search--input

    &--input {
      width: 100%;

      padding: 13px 20px;
      border: 1px solid rgba(174, 182, 206, 0.3);
      border-right: 0;
      background: var(--white);

      font-size: 0.875rem;
      line-height: 120%;
      color: var(--text-color);

      &:focus {
        color: var(--text-color);
      }

      &::placeholder {
        color: var(--light-grey);
      }

      transition: color 0.2s ease, border-color 0.2s ease;
      @media (any-hover: hover) {
        &:hover {
          border-color: #aeb6ce;
        }
      }
    }

    // .section-table__search--submit

    &--submit {
      position: relative;
      z-index: 2;
    }
  }

  // .section-table__change-date

  &__change-date {
    // .section-table__change-date--target

    &--target {
      width: 100%;
      height: 45px;
      @include _992 {
        width: 110px;
      }

      border: 1px solid rgba(174, 182, 206, 0.3);
      background: var(--white);

      color: var(--text-color);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 120%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0 20px;
    }

    // .section-table__change-date--target-arrow

    &--target-arrow {
    }

    // .section-table__change-date--block

    &--block {
      width: 200px;

      max-height: none;
      overflow-y: visible;

      min-width: auto;

      @include _992 {
        width: 239px;
      }
    }

    // .section-table__change-date--slider

    &--slider {
      padding: 15px;
      width: 200px;
      @include _992 {
        padding: 15px 30px;
        width: 239px;
      }

      .splide__arrow {
        background: transparent;
        color: var(--heading-color);

        svg {
          width: 7px;
          height: auto;
        }

        &--prev {
          left: 0;
          @include _992 {
            left: 5px;
          }
        }

        &--next {
          right: 0;
          @include _992 {
            right: 5px;
          }
        }
      }

      .splide__slide label {
        display: flex;

        input {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;

          white-space: nowrap;
          clip-path: inset(100%);
          clip: rect(0 0 0 0);
          overflow: hidden;
        }

        input:checked + span {
          background: var(--accent);
          color: #fff;
        }

        input:disabled + span {
          color: var(--text-color);
          pointer-events: none;
        }

        span {
          padding: 7px 2px;
          @include _992 {
            padding: 7px 10px;
            font-size: 0.875rem;
          }

          display: block;
          cursor: pointer;

          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 120%;

          transition: background 0.15s ease, color 0.15s ease;
        }

        @media (any-hover: hover) {
          &:hover span {
            color: var(--accent);
          }

          &:active span {
            color: var(--accent-dark);
          }
        }

        display: block;
      }
    }
  }

  // .section-table__change-full-date

  &__change-full-date {
    // .section-table__change-full-date--target

    &--target {
      min-width: 100%;
      height: 45px;
      @include _992 {
        min-width: 164px;
      }

      border: 1px solid rgba(174, 182, 206, 0.3);
      background: var(--white);

      color: var(--text-color);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 120%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0 20px;

      span {
        padding-right: 15px;

        width: 130px;
        text-align: start;
      }
    }

    // .section-table__change-full-date--target-arrow

    &--target-arrow {
    }

    // .section-table__change-full-date--block

    &--block {
      width: 260px;

      max-height: none;
      overflow-y: visible;

      min-width: auto;

      @include _992 {
        width: 260px;
      }
    }

    // .section-table__change-full-date--months

    &--months {
      border-bottom: 1px solid rgba(94, 100, 115, 0.1);

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      label {
        position: relative;

        input {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;

          white-space: nowrap;
          clip-path: inset(100%);
          clip: rect(0 0 0 0);
          overflow: hidden;
        }

        input:checked + span {
          background: var(--accent);
          color: var(--white);
        }

        span {
          cursor: pointer;
          user-select: none;
          display: block;
          padding: 10px 25px;
          color: var(--text-color);

          @media (any-hover: hover) {
            transition: background 0.2s ease, color 0.2s ease;
            &:hover {
              background: rgba(151, 160, 187, 0.1);
              color: var(--accent);
            }

            &:active {
              background: rgba(151, 160, 187, 0.1);
              color: var(--accent-dark);
            }
          }
        }
      }
    }

    // .section-table__change-full-date--slider

    &--slider {
      padding: 15px 30px;
      @include _992 {
        padding: 15px 30px;
      }

      .splide__arrow {
        background: transparent;
        color: var(--heading-color);

        svg {
          width: 7px;
          height: auto;
        }

        &--prev {
          left: 0;
          @include _992 {
            left: 5px;
          }
        }

        &--next {
          right: 0;
          @include _992 {
            right: 5px;
          }
        }
      }

      .splide__slide label {
        display: flex;

        input {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;

          white-space: nowrap;
          clip-path: inset(100%);
          clip: rect(0 0 0 0);
          overflow: hidden;
        }

        input:checked + span {
          background: var(--accent);
          color: #fff;
        }

        input:disabled + span {
          color: inherit;
          pointer-events: none;
          opacity: 0.5;
        }

        input:checked:disabled + span {
          color: white;
          pointer-events: none;
          opacity: 0.5;
        }

        span {
          padding: 7px 0;
          @include _992 {
            padding: 7px 10px;
            font-size: 0.875rem;
          }

          display: block;
          cursor: pointer;

          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 120%;

          transition: background 0.15s ease, color 0.15s ease;
        }

        @media (any-hover: hover) {
          &:hover span {
            color: var(--accent);
          }

          &:active span {
            color: var(--accent-dark);
          }
        }

        display: block;
      }
    }
  }

  // .section-table__export

  &__export {
    &.is-min &--target {
      height: 44px;
      @include _992 {
        width: 100%;
        min-width: 120px;
      }
    }

    &.is-active &--target {
      border-color: var(--accent-light);
      background: var(--white);
      color: var(--theme-text-color-2);
    }

    // .section-table__export--target

    &--target {
      width: 100%;
      height: 45px;
      @include _992 {
        //width: 120px;
        padding-left: 20px;
        padding-right: 20px;
      }

      border: 1px solid var(--accent);
      background: transparent;
      color: var(--theme-text-color-2);

      display: inline-flex;
      align-items: center;
      justify-content: center;

      transition: color 0.2s ease, background 0.2s ease, border-color 0.2s ease;

      @media (any-hover: hover) {
        &:hover {
          border-color: var(--accent-light);
          color: var(--accent-light);
        }

        &:active {
          border-color: var(--accent-dark);
          color: var(--theme-text-color-2);
        }
      }

      svg {
        flex: 0 0 16px;
        margin-left: 10px;
      }
    }

    // .section-table__export--block

    &--block {
      width: max-content;
    }
  }

  // .section-table__main

  &__main {
    margin-top: 20px;

    position: relative;
    z-index: -1;

    width: 100%;

    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(63, 70, 94, 0.08);

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 0;
      width: 160px;
      height: 100%;
      transform: translate(100%, 0);

      box-shadow: 0px 0px 20px 0px rgba(63, 70, 94, 0.08);
      @include _992 {
        display: none;
      }
    }

    &.is-row-mode {
      background: transparent;
      box-shadow: none;
    }

    &.is-row-mode
      &--container
      > .simplebar-wrapper
      > .simplebar-mask
      > .simplebar-offset
      > .simplebar-content-wrapper
      > .simplebar-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;

      &::before,
      &::after {
        display: none;
      }
    }

    &.is-row-mode &--wrapper {
      min-width: 600px;
      background: #fff;
      box-shadow: 0px 0px 20px 0px rgba(63, 70, 94, 0.08);
    }

    &--container {
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      /* max-width: calc(100vw - 15px - 15px);
      @include _992 {
          max-width: calc(100vw - var(--aside-width, 230px) - 30px - 14px);
          transition: var(--transition-width, none);
      }

      @media (min-width: 1440px) {
          max-width: calc(100vw - var(--aside-width, 230px) - 50px - 50px);
      } */

      /* & > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper > .simplebar-content {

      } */
    }

    &--wrapper {
      min-width: 1150px;
      @include _992 {
        min-width: 1350px;
      }
    }

    // .section-table__main--param

    &--param {
      padding: 5px 5px;
      @include _992 {
        padding: 15px;
      }

      display: flex;
      align-items: center;

      &.is-center {
        width: 100%;
        justify-content: center;
      }

      svg {
        flex: 0 0 13px;
        margin-right: 7px;
        margin-bottom: 1px;
      }
    }

    // .section-table__main--sort

    &--sort {
      width: 100%;

      &.is-center .section-table__main--sort-target {
        justify-content: center;
      }

      &.is-active .section-table__main--sort-target {
        color: var(--accent);
      }
    }

    // .section-table__main--sort-target

    &--sort-target {
      display: inline-flex;
      align-items: center;

      transition: color 0.2s ease;

      width: 100%;

      line-height: 120%;
      color: #aeb6ce;
      font-size: 0.75rem;
      @include _992 {
        font-size: 0.875rem;
      }

      svg:first-child {
        flex: 0 0 13px;
        margin-right: 5px;
      }

      svg:last-child {
        flex: 0 0 13px;
        margin-left: 5px;
      }

      padding: 10px 15px;
      text-align: start;

      background: transparent;

      .drop-down__target--arrow {
        transition: transform 0.3s ease;
      }

      &.is-active {
        color: var(--accent);
      }
    }

    // .section-table__main--sort-block

    &--sort-block {
      white-space: nowrap;
      width: auto;

      min-width: 150px;

      a {
        text-align: start;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-bottom: 2px;
          flex: 0 0 15px;
          margin-right: 5px;
        }
      }
    }

    // .section-table__main--project-name

    &--project-name {
      width: 100%;
      position: static;
      @include _992 {
        position: relative;
      }
    }

    &--project-name.is-active &--project-name-target {
      color: var(--accent);
    }

    // .section-table__main--project-name-target

    &--project-name-target {
      padding: 15px;

      background: transparent;

      transition: color 0.2s ease;

      &.is-active {
        color: var(--accent);
      }

      font-size: 0.75rem;
      @include _992 {
        font-size: 0.875rem;
      }
      color: #aeb6ce;
      line-height: 120%;

      display: flex;
      align-items: center;
      justify-content: center;

      &.is-start {
        justify-content: flex-start;
      }

      text-align: start;

      width: 100%;

      svg:first-child {
        flex: 0 0 13px;
        margin-right: 5px;
      }

      svg:last-child {
        flex: 0 0 13px;
        margin-left: 5px;
      }
    }

    // .section-table__main--project-name-block

    &--project-name-block {
      z-index: 3;
      width: calc(100vw - 30px);
      max-height: none;
      overflow-y: visible;

      //left: 0 !important;
      @include _992 {
        //left: 0 !important;
        width: 440px;
      }
    }

    // .section-table__main--project-descr

    &--project-descr {
      width: 100%;
    }

    // .section-table__main--project-desrc-target

    &--project-desrc-target {
      padding: 15px;

      background: transparent;

      transition: color 0.2s ease;

      font-size: 0.75rem;
      @include _992 {
        font-size: 0.875rem;
      }
      color: #aeb6ce;
      line-height: 120%;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: start;

      width: 100%;

      svg:first-child {
        flex: 0 0 13px;
        margin-right: 5px;
      }

      svg:last-child {
        flex: 0 0 13px;
        margin-left: 5px;
      }
    }

    &--project-descr-block {
      width: 100%;

      z-index: 3;
    }

    // .section-table__main--func

    &--func {
      width: 100%;
    }

    // .section-table__main--func-target

    &--func-target {
      width: 100%;
      padding: 15px;

      background: transparent;

      &.is-active {
        color: var(--accent);
      }

      transition: color 0.2s ease;

      font-size: 0.75rem;
      @include _992 {
        font-size: 0.875rem;
      }
      color: #aeb6ce;
      line-height: 120%;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: start;

      width: 100%;

      svg:first-child {
        flex: 0 0 13px;
        margin-right: 5px;
      }

      svg:last-child {
        flex: 0 0 13px;
        margin-left: 5px;
      }
    }

    // .section-table__main--func-block

    &--func-block {
      z-index: 3;
      //min-width: 220px;
      a {
        text-align: start;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-bottom: 2px;
          flex: 0 0 15px;
          margin-right: 5px;
        }
      }
    }
  }

  &__main.is-alternative & {
    &__row {
      &::before {
        display: none;
      }

      --col-accent: rgba(251, 201, 0, 0.05);
    }

    &__head-th:not(:nth-child(2)) {
      justify-content: center;
    }

    &__row.is-active .section-table__param::after {
      opacity: 0.2;
    }

    &__param {
      position: relative;

      &:first-child {
        position: sticky;
        background: var(--white);
        z-index: 1;
      }

      @include _992 {
        position: relative;
        &:first-child {
          position: relative;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 1px);
        height: calc(100% + 1px);
        background: #97a0bb;
        opacity: 0;
      }

      &.is-active::after {
        opacity: 0.2;
      }

      @media (any-hover: hover) {
        &:hover {
          &::after {
            opacity: 0.2;
          }
        }
      }
    }

    &__row {
      @media (any-hover: hover) {
        &:hover {
          box-shadow: none;
        }

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  &__main:not(.table-timesheet) & {
    &__head-th {
      &:nth-child(1) {
        position: sticky;
        left: 0;
        top: 0;
        background: var(--white);
        z-index: 2;

        display: flex;

        > span > span {
          display: flex;
          align-items: center;

          &:nth-child(1) {
            width: 35px;
            text-align: center;
            justify-content: center;
            margin-right: 5px;
          }

          &:nth-child(2) {
            flex: 1 1 auto;
            //justify-content: flex-start;
            justify-content: center;
            padding: 0;

            .section-table__main--sort-target {
              //padding-left: 0;
            }

            @include _992 {
              .section-table__main--sort-target {
                padding-left: 15px;
              }
            }
          }
        }

        @include _992 {
          position: static;
          background: transparent;
        }
      }
    }

    &__row {
    }

    /* &__param {
        &:first-child {
            padding: 15px 5px;

            display: flex;

            > span {
                display: block;
                &:nth-child(1) {
                    width: 40px; flex: 0 0 40px;
                    text-align: center;
                    margin-right: 0px;
                }

                &:nth-child(2) {
                    flex: 1 1 auto;
                    text-align: start;

                    display: -webkit-box;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                    box-orient: vertical;
                }
            }
        }

        &:nth-child(1) {
            position: sticky;
            left: 0; top: 0;
            background: var(--white);
            @include _992 {
                position: static;
                background: transparent;
            }
        }

        &:nth-child(2) {
            padding: 15px 5px;
            @include _992 {
                padding: 15px 25px;
                text-align: center;
            }
        }
    } */
  }

  &__main.table-timesheet & {
    &__head-th {
      &:nth-child(1) {
        position: sticky;
        left: 0;
        top: 0;
        //transform: translate3d(var(--scroll-x,0px), 0, 0.0001px);
        background: var(--white);
        z-index: 3;

        transition: var(--transition, none);
        border-left: 1px solid rgba(94, 100, 115, 0.1);

        .section-table__main--param {
          padding: 0;
        }

        > span > span {
          &:nth-child(1) {
            width: 40px;
            text-align: center;
          }

          &:nth-child(2) {
            flex: 1 1 auto;

            .section-table__main--sort-target {
              padding-left: 15px;
              padding-right: 5px;

              justify-content: center;
            }

            @include _992 {
              .section-table__main--sort-target {
                padding-left: 15px;
              }
            }
          }
        }

        @include _992 {
          position: static;
          background: transparent;
        }
      }

      &:nth-child(2) {
        justify-content: center;
      }

      padding: 0 5px;
      @include _992 {
        padding: 8.5px 5px;
      }

      border-right: 1px solid rgba(95, 100, 114, 0.1);
    }

    &__row {
      &::before {
        display: none;
      }

      &:nth-child(even) {
        background: transparent;

        &.is-even-mode {
          background: rgb(150, 159, 186, 0.07);
        }
      }

      @media (any-hover: hover) and (min-width: 992px) {
        &.drop-down-2:hover {
          box-shadow: none;
        }

        &.drop-down-2:active {
          box-shadow: none;
        }
      }
    }

    &__row-block {
      position: relative;

      &.is-row-span {
        position: absolute;
        top: 0;
      }
    }

    &__row-block:not(:first-child)
      .section-table__row-block--span-params
      .section-table__param {
      border-top: 1px solid rgba(94, 100, 115, 0.1);
    }

    &__row-block.on-hover
      .section-table__row-block--span-params
      .section-table__param {
      border-left: 1px solid rgba(94, 100, 115, 0.1);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(151, 160, 187, 0.2);
      }
    }

    &__row-block.on-hover
      .section-table__row-block--span-params
      .accent-on-active {
      color: var(--accent);
    }

    &__row-block.on-active
      .section-table__row-block--span-params
      .accent-on-active {
      color: var(--accent);
    }

    &__row-block:not(:last-child)
      .section-table__row-block--list
      .section-table__row:last-child {
      border-bottom: 1px solid rgba(95, 100, 114, 0.1);
      //margin-bottom: -1px;
    }

    &__row-block.on-active
      .section-table__row-block--span-params
      .section-table__param {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //background: rgba(151,160,187,0.2);
        background: #eaecf1;
        z-index: -1;
      }
    }

    &__row-block.on-active
      .section-table__row-block--list
      .section-table__row.is-active-drop-down
      .section-table__param {
      background: #eaecf1;
    }

    &__row-block.on-active
      .section-table__row-block--list
      .section-table__row.is-active-drop-down
      .section-table__param:first-child::after {
      opacity: 0;
    }

    &__row-block.on-active
      .section-table__row-block--list
      .section-table__row.is-active-drop-down
      .section-table__param:first-child::before {
      background: #eaecf1;
    }

    &__row-block--span-params .section-table__param {
      @include _992 {
        position: absolute;
      }

      display: grid;
      align-items: center;
      justify-content: center;

      background: var(--white);

      &:nth-child(1) {
        position: sticky;
        left: 0px;
        top: 0;
        margin-bottom: var(--negative-height);
        transform: translate(40px, 0);

        @include _992 {
          position: absolute;
          left: 70px;
          top: 0;
          width: 148px;
          transform: none;

          margin-bottom: 0;
          height: 100%;

          background: var(--white);
        }

        z-index: 1;
        width: 91px;
        height: var(--height);
        border-right: 1px solid rgba(94, 100, 115, 0.1);
      }

      &:nth-child(2) {
        position: absolute;
        right: 0px;
        top: 0;
        width: 113px;
        height: 100%;
        border-right: 1px solid rgba(94, 100, 115, 0.1);
        border-left: 1px solid rgba(94, 100, 115, 0.1);
        z-index: 1;
      }
    }

    //.section-table__row

    @media (any-hover: hover) and (min-width: 992px) {
      &__row-block--list
        .section-table__row.drop-down-2:hover
        .section-table__param {
        background: rgba(151, 160, 187, 0.2);
      }
    }

    &__row-block--list .section-table__row {
      position: relative;
      //border-bottom: 1px solid rgba(95, 100, 114, 0.1);
    }

    &__row-block--list .section-table__param {
      border-right: 1px solid rgba(94, 100, 115, 0.1);
      display: flex;

      &:nth-child(1) {
        grid-column: 1;
        position: sticky;
        left: 0;
        top: 0;

        padding: 0;

        > span {
          display: block;
          width: 40px;
        }

        border-left: 1px solid rgba(94, 100, 115, 0.1);
        @media (max-width: 992px) {
          background: var(--white);
        }

        @include _992 {
          grid-column: 1;
          position: static;
          height: auto;
          //border-bottom: 1px solid rgba(95, 100, 114, 0.1);
          background: transparent;
          > span {
            display: inline;
          }

          justify-content: center;
        }

        text-align: center;
        justify-content: flex-start;
      }

      &:nth-child(2) {
        grid-column: 2;
        @include _992 {
          grid-column: 3;
        }

        text-align: center;
        justify-content: center;

        border-left: 1px solid rgba(94, 100, 115, 0.1);
        margin-left: -1px;
      }

      &:nth-child(3) {
        grid-column: 3;
        @include _992 {
          grid-column: 4;
        }
      }

      &:nth-child(4) {
        grid-column: 4;
        @include _992 {
          grid-column: 5;
        }
      }

      &:nth-child(5) {
        grid-column: 5;
        @include _992 {
          grid-column: 6;
        }
        //justify-content: center;
        //text-align: center;
      }

      &:nth-child(6) {
        grid-column: 6;
        @include _992 {
          grid-column: 7;
        }
        justify-content: center;
        text-align: center;
      }
    }

    &__param {
      &.is-row-span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__main.add-border & {
    &__head-th {
      border-right: 1px solid rgba(95, 100, 114, 0.1);

      &:nth-child(1),
      &:nth-child(2) {
        border-left: 1px solid rgba(95, 100, 114, 0.1);
      }
    }

    &__row {
      //border: 0;
      /* &::before {
          display: none;
      } */
    }

    &__param {
      border-right: 1px solid rgba(95, 100, 114, 0.1);

      &:nth-child(1),
      &:nth-child(2) {
        border-left: 1px solid rgba(95, 100, 114, 0.1);
      }
    }
  }

  // .section-table__head

  &__head {
    position: relative;
    border-bottom: 1px solid rgba(94, 100, 115, 0.1);
  }

  // .section-table__head-row

  &__head-row {
    display: grid;
    grid-template-columns: var(--grid-columns-mob);
    @include _992 {
      grid-template-columns: var(--grid-columns-tablet, var(--grid-columns));
    }

    @media (min-width: 1440px) {
      grid-template-columns: var(--grid-columns);
    }
    //align-items: center;
  }

  // .section-table__head-th

  &__head-th {
    display: flex;
    align-items: center;
    //justify-content: center;

    min-height: 45px;
    text-align: start;
    font-weight: 400;

    font-size: 0.75rem;
    @include _992 {
      font-size: 0.875rem;
      min-height: 63px;
    }

    line-height: 120%;
    color: #aeb6ce;

    padding: 0 5px;
    @include _992 {
      padding: 0 5px;
    }

    &:nth-child(5) .section-table__main--param,
    &:nth-child(1) .section-table__main--param {
      width: 100%;

      display: flex;
      justify-content: center;
    }
  }

  // .section-table__body

  &__body {
  }

  // .section-table__row

  &__row {
    position: relative;
    display: grid;
    grid-template-columns: var(--grid-columns-mob);
    @include _992 {
      grid-template-columns: var(--grid-columns-tablet, var(--grid-columns));
    }

    @media (min-width: 1440px) {
      grid-template-columns: var(--grid-columns);
    }

    //border: 1px solid transparent;
    &.is-even-mode {
      background: rgb(150, 159, 186, 0.07);
    }

    transition: box-shadow 0.2s ease;

    &:nth-child(even) {
      background: rgb(150, 159, 186, 0.07);
      //border-bottom: 1px solid rgba(95, 100, 114, 0.1);
    }

    &.is-active-drop-down::before {
      opacity: 1;
      visibility: visible;
    }

    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 1px solid var(--accent);
      pointer-events: none;
      z-index: 2;

      transition: opacity 0.3s ease, visibility 0.3s ease;
      opacity: 0;
      visibility: hidden;
    }

    /* &.is-active-drop-down .section-table__param {
        background: rgba(151,160,187,0.2);
    } */

    @media (any-hover: hover) and (min-width: 992px) {
      &.drop-down-2:hover,
      &.drop-down-2:nth-child(even):hover,
      &.drop-down-2.is-even-mode:hover {
        //box-shadow: 0px 0px 30px 0px rgba(63, 70, 94, 0.15);
        background: var(--white);
      }

      &.drop-down-2:active {
        //box-shadow: 0px 0px 30px 0px rgba(63, 70, 94, 0.15);
        background: var(--white);
      }

      &.drop-down-2:hover .section-table__param {
        background: rgba(151, 160, 187, 0.2);
      }
    }
  }

  &__row-block--span-params {
    pointer-events: none;
  }

  &__row:nth-child(even) &__param:first-child {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      //background: rgb(150, 159, 186, 0.07);
      z-index: -1;
    }

    @include _992 {
      background: transparent;
    }
  }

  // .section-table__param

  &__param {
    &.is-row-span {
      grid-row: var(--row-span);
    }

    &.is-accent {
      color: var(--accent);
    }

    padding: 10px 18px;

    color: var(--text-color);
    line-height: 120%;

    min-height: 44px;

    font-size: 0.75rem;
    @include _992 {
      padding: 15px 18px;
      font-size: 0.875rem;

      min-height: 51px;
    }

    display: flex;
    align-items: center;

    &.is-center {
      justify-content: center;
      text-align: center;
    }

    &:first-child {
      padding: 15px 5px;

      display: flex;

      > span {
        display: block;

        &:nth-child(1) {
          width: 40px;
          flex: 0 0 40px;
          text-align: center;
          margin-right: 0px;
        }

        &:nth-child(2) {
          flex: 1 1 auto;
          text-align: start;

          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-clamp: 2;
          box-orient: vertical;
        }
      }
    }

    &:nth-child(1) {
      position: sticky;
      left: 0;
      top: 0;
      background: var(--white);
      @include _992 {
        position: static;
        background: transparent;
      }
    }

    &:nth-child(2) {
      padding: 15px 5px;
      @include _992 {
        padding: 15px 5px;
        text-align: center;
      }
    }
  }

  // .section-table__footer

  &__footer {
    margin-top: 20px;

    //display: grid;
    //grid-template-columns: 1fr 1fr;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: row-reverse;
    }

    @include _992 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    gap: 10px;
  }

  // .section-table__see-more

  &__see-more {
  }

  // .section-table__row-per-page

  &__row-per-page {
    display: flex;
    align-items: center;

    > span {
      padding-right: 10px;

      color: var(--text-color);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 120%;
    }

    // .section-table__row-per-page--select

    &--select {
      width: 88px;
    }
  }
}

.table-employees {
  --grid-columns: 70px 1fr 1.3fr 0.9fr 1.2fr 180px 160px 0.7fr;
  --grid-columns-tablet: 70px 0.8fr 1fr 0.8fr 1.2fr 0.8fr 1.1fr 0.9fr;
  --grid-columns-mob: 165px 1fr 0.7fr 1fr 0.8fr 1fr 0.9fr;

  .section-table__param a {
    white-space: nowrap;
  }
}

.table-vacations {
  --grid-columns: 55px 170px 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 4.5% 4.5% 4.5% 4.5%
    4.5% 4.5% auto;
  --grid-columns-tablet: 55px 150px 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 4.75% 4.75%
    4.75% 4.75% 4.75% 4.75% auto;
  --grid-columns-mob: 165px 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% auto;

  .section-table__main--wrapper {
    min-width: 1300px;

    @include _992 {
      min-width: 1300px;
    }

    @media (min-width: 1440px) {
      min-width: 1350px;
    }
  }

  .section-table__row {
    transition: none;
  }

  .section-table__row.is-even-mode .section-table__param:first-child::after {
    opacity: 1;
  }

  @media (any-hover: hover) {
    .section-table__row.drop-down-2:hover {
      box-shadow: none;
    }
  }

  .section-table__head-th {
    text-align: center;
    padding: 5px 1px;

    &:nth-child(2) {
      text-align: start;
    }

    &:nth-child(1) {
      position: relative;
      z-index: 3;
    }

    .section-table__main--param {
      padding: 0px 5px;
      @include _992 {
        padding: 18.5px 5px;
      }
    }
  }

  .section-table__param {
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(3) {
      text-align: start;
      justify-content: flex-start;
    }
  }
}

.table-projects {
  --grid-columns: 55px 165px 140px auto 110px;
  --grid-columns-mob: 165px 200px calc(100% - 415px);

  &.is-row-mode .section-table__main--wrapper {
    min-width: 625px;
  }

  &:not(.is-row-mode) .section-table__main--wrapper {
    min-width: 500px;
  }

  .section-table__row:nth-child(even) {
    background: transparent;
  }

  .section-table__row {
    border-bottom: 1px solid rgba(95, 100, 114, 0.1);
  }

  &.add-border.visible-on-desktop .section-table__head-th:nth-child(2) {
    border-left: 0;
  }

  /* .section-table__param {
      background: var(--white);
  } */

  .section-table__param:nth-child(1) {
    left: 1px;

    &::after {
      display: none;
    }
  }

  .section-table__param:nth-child(1) span:nth-child(2) {
    text-align: center;
  }

  .section-table__param:not(:nth-child(4)) {
    text-align: center;
  }

  @include _992 {
    .section-table__param:not(:nth-child(3)) {
      text-align: left;
    }
  }
}

.table-timesheet {
  --grid-columns: 70px 148px 166px 13% 140px auto 90px 140px 101px 112px;
  // --grid-columns-tablet: 70px 148px 166px 13% 180px auto 90px 150px 120px 150px;
  --grid-columns-mob: 131px 150px 15% auto 140px 120px 120px 85px 112px;

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 131px;
    height: 100%;
    transform: translate(100%, 0);

    box-shadow: 0px 0px 20px 0px rgba(63, 70, 94, 0.08);
    @include _992 {
      display: none;
    }
  }

  .section-table__body--wrapper {
    min-width: 1300px;
  }

  /* width: calc(100vw - 15px - 15px);
  @include _992 {
      width: calc(100vw - var(--aside-width, 230px) - 30px - 15px);
  }

  @media (min-width: 1440px) {
      width: calc(100vw - var(--aside-width, 230px) - 50px - 50px);
  } */

  .section-table__main--container {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .section-table__row:hover .section-table__param {
    background: transparent;
  }

  @media (any-hover: hover) {
    .section-table__row {
      transition: none;
    }
    .section-table__row:hover:nth-child(even).is-even-mode {
      background: var(--white) !important;
    }

    //.section-table__main.table-timesheet .section-table__row:nth-child(even).is-even-mode

    //.section-table__main.table-timesheet .section-table__row-block--list .section-table__row.drop-down-2:hover .section-table__param
  }

  .section-table__param {
    display: flex;
    align-items: center;
  }
}

.project-popup {
  padding-top: 20px;

  // .project-popup__body

  &__body {
    max-height: 30vh;
    @include _992 {
      max-height: 375px;
    }
    overflow-y: auto;

    padding: 0 20px 20px;
    @include _992 {
      padding: 0 30px 0px;
    }

    .simplebar-track {
      right: 10px;
    }
  }

  // .project-popup__block

  &__block {
    &:not(:first-child) {
      margin-top: 20px;
    }

    h2 {
      color: #97a0bb;
      font-weight: 500;
      line-height: 120%;
      font-size: 0.75rem;

      @include _992 {
        font-size: 0.875rem;
      }

      margin-bottom: 7px;
    }
  }

  // .project-popup__list

  &__list {
  }

  // .project-popup__item

  &__item {
    a {
      display: block;
      position: relative;

      padding: 11px 0;
      transition: color 0.2s ease;

      @include _992 {
        font-size: 0.875rem;
      }

      color: var(--text-color);
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 140%;
      text-decoration: none;

      @media (any-hover: hover) {
        &:hover {
          color: var(--accent);

          &::before {
            opacity: 1;
          }
        }

        &:active {
          color: var(--accent-dark);

          &::before {
            opacity: 1;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        top: 0;
        width: calc(100% + 25px + 25px);
        height: 100%;
        @include _992 {
          width: calc(100% + 30px + 30px);
        }
        background: rgba(151, 160, 187, 0.1);

        transition: opacity 0.2s ease;
        opacity: 0;
      }
    }
  }

  // .project-popup__search

  &__search {
    padding: 20px 20px 20px;
    @include _992 {
      padding: 20px 30px 30px;
    }

    display: grid;
    grid-template-columns: auto 125px;

    input {
      width: 100%;
    }
  }
}

.section-table {
  // .section-table__add-task

  &__add-task {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    @include _992 {
      grid-template-columns: 70px 170px 170px 100px auto;
    }

    align-items: center;

    @media (min-width: 1440px) {
      display: flex;
      flex-wrap: wrap;
      .section-table__add-task--submit.btn {
        min-width: 200px;
      }
    }

    gap: 10px;

    position: relative;
    z-index: 3;

    // .section-table__add-task--back

    &--back {
    }

    // .section-table__add-task--set-date

    &--set-date {
      @include _992 {
        width: 170px;
      }

      display: flex;
      position: relative;

      > input {
        width: 100%;

        font-size: 0.875rem;
        cursor: pointer;
      }

      > input:focus + * + svg {
        transform: scaleY(-1);
        color: var(--accent);
      }

      > svg {
        position: absolute;
        right: 20px;
        top: 50%;
        translate: 0 -50%;
        color: #aeb6ce;
        pointer-events: none;

        transition: color 0.2s ease, transform 0.2s ease;
      }
    }

    // .section-table__add-task--project

    &--project {
      font-size: 0.875rem;
      grid-column-start: 1;
      grid-column-end: 3;

      @include _992 {
        width: auto;
        flex: 1;
        grid-column: auto;
        grid-row: auto;
      }

      @media (min-width: 1440px) {
        width: 170px;
        flex: none;
      }

      &-target {
        padding: 14px 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        color: #5f6472;
        line-height: 120%;
        font-weight: 400;
        font-size: 0.875rem;

        border: 1px solid rgba(174, 182, 206, 0.3);

        svg {
          color: #aeb6ce;
        }

        width: 100%;

        background: var(--white);
      }
    }

    // .section-table__add-task--text

    &--text {
      grid-row: 3;
      grid-column: 2 span;
      min-width: 150px;

      @media screen and (max-width: 576px) {
        grid-column: 1 span;
      }
      @include _992 {
        grid-column: auto;
        grid-row: auto;
      }

      @media screen and (max-width: 1650px) {
        & {
          order: 8;
          min-width: 600px;
        }
      }
      @media screen and (max-width: 1440px) {
        & {
          order: 0;
          min-width: 200px;
        }
      }

      @media (min-width: 1440px) {
        flex: 1;
      }

      .input {
        width: 100%;

        font-size: 0.875rem;
      }
    }

    // .section-table__add-task--time

    &--time {
      @include _768 {
        grid-column: 2;
        grid-row: 2;
        grid-column-start: 1;
        grid-column-end: 3;
      }
      @include _992 {
        grid-column: 2 span;
        grid-row: auto;
      }

      @media (min-width: 1440px) {
        width: 170px;
      }

      font-size: 0.875rem;
    }

    // .section-table__add-task--hours

    &--hours {
      @media (min-width: 1440px) {
        width: 100px;
      }

      .input {
        width: 100%;

        font-size: 0.875rem;
      }
    }

    // .section-table__add-task--submit

    &--submit {
      @media screen and (max-width: 1650px) {
        & {
          flex: 1;
        }
      }
      @media screen and (max-width: 768px) {
        & {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
      @media screen and (max-width: 576px) {
        & {
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }
    }
  }

  // .section-table__time

  &__time {
    // .section-table__time--target

    &--target {
      display: flex;
      align-items: center;

      color: var(--text-color);

      svg {
        color: #aeb6ce;
      }

      border: 1px solid rgba(174, 182, 206, 0.3);
      background: var(--white);

      padding: 14px 20px;

      width: 100%;
      text-align: start;

      span {
        flex: 1 1 auto;
      }
    }

    // .section-table__time--block

    &--block {
      width: 252px;
      padding: 20px 25px;
    }

    // .section-table__time--item

    &--item {
      display: grid;
      grid-template-columns: 1fr 2fr;

      align-items: center;

      &-additional {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 14px;

        li {
          width: 100%;
        }

        button {
          border: 1px solid rgba(174, 182, 206, 0.5);
          background: var(--ffffff, #fff);
          color: var(--accent);
          font-size: 14px;
          font-weight: 400;
          line-height: 100%;
          text-align: center;
          padding-top: 15px;
          padding-bottom: 15px;
          cursor: pointer;
          width: 100%;
          display: block;
          transition: all 0.3s ease;

          &:hover {
            transform: scale(1.05);
          }

          &:active {
            transform: scale(0.98);
          }
        }
      }

      &:first-child {
        border-bottom: 1px solid rgba(95, 100, 114, 0.1);
        margin-bottom: 15px;
        padding-bottom: 15px;
      }

      &:not(:first-child) {
        border-top: 1px solid rgba(95, 100, 114, 0.1);
        margin-top: 15px;
        padding-top: 15px;
      }

      &-col {
        &:nth-child(1) {
          span {
            color: var(--text-color);
            font-size: 0.875rem;
            line-height: 120%;
          }
        }

        &:nth-child(2) {
          display: flex;
          align-items: center;

          gap: 5px;
        }
      }
    }

    // .section-table__time--select

    &--select {
      width: 70px;
    }
  }
}

.section-table {
  // .section-table__header

  &__header {
    // .section-table__header--add-costs

    &--add-costs {
    }
  }

  // .section-table__add-costs

  &__add-costs {
    display: grid;
    gap: 10px;

    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 992px) {
      .section-table__add-expense--submit {
        grid-column-start: auto;
        grid-column-end: auto;
      }
      .section-table__add-task--project + .select {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    @include _992 {
      margin-top: -1px;
      grid-template-columns: 56px 170px 170px 100px auto;
    }

    @media (min-width: 1440px) {
      grid-template-columns: 56px 170px 170px 170px minmax(100px, auto) 130px 120px 150px;
      .section-table__add-expense--submit {
        // grid-column-start: 1;
        // grid-column-end: 3;
      }
    }
    @media (min-width: 1650px) {
      grid-template-columns: 56px 170px 170px 170px minmax(100px, auto) 130px 120px 120px 170px;
      .section-table__add-expense--submit {
        // grid-column-start: auto;
        // grid-column-end: auto;
      }
    }

    // .section-table__add-costs--back

    &--back {
    }

    // .section-table__add-costs--set-date

    &--set-date {
      @include _992 {
        width: 170px;
      }

      display: flex;
      position: relative;

      > input {
        width: 100%;

        font-size: 0.875rem;
      }

      > input:focus + * + svg {
        transform: scaleY(-1);
        color: var(--accent);
      }

      > svg {
        position: absolute;
        right: 20px;
        top: 50%;
        translate: 0 -50%;
        color: #aeb6ce;

        transition: color 0.2s ease, transform 0.2s ease;
      }
    }

    // .section-table__add-costs--project

    &--project {
      min-height: 100%;
      font-size: 0.875rem;
    }

    // .section-table__add-costs--text

    &--text {
      grid-column: 2 span;
      @include _992 {
        grid-column: auto;
      }

      @media screen and (max-width: 1650px) {
        & {
          grid-row: 2;
          grid-column-start: 1;
          grid-column-end: 9;
        }
      }
      @media screen and (max-width: 1440px) {
        & {
          grid-row: auto;
          grid-column-start: auto;
          grid-column-end: auto;
        }
      }

      input {
        width: 100%;

        font-size: 0.875rem;
      }
    }

    // .section-table__add-costs--cost

    &--cost {
      grid-column: 2;
      grid-row: 2;
      grid-column-start: 1;
      grid-column-end: 3;

      @include _992 {
        grid-column: 2 span;
        grid-row: auto;
      }

      @media (min-width: 1440px) {
        grid-column: auto;
      }

      input {
        width: 100%;
        font-size: 0.875rem;
      }
    }

    &--submit {
      grid-column: 2 span;
      @include _992 {
        grid-column: auto;
      }
    }
  }
}

.table-costs {
  --grid-columns: 70px 148px 165px 13% 12% auto 100px 130px 140px 70px;
  --grid-columns-mob: 131px 155px 15% 15% auto 140px 140px 140px 70px;

  &::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 131px;
    height: 100%;
    transform: translate(100%, 0);

    box-shadow: 0px 0px 20px 0px rgba(63, 70, 94, 0.08);
    @include _992 {
      display: none;
    }
  }

  /* &.add-border .section-table__row {
      border: 1px solid transparent;
      &.is-active-drop-down {
          border-color: var(--accent);
      }
  } */

  .section-table__main--wrapper {
    min-width: 1200px;
    @include _992 {
      min-width: 1300px;
    }
  }
}

//.section-table__row {
//	border-bottom: 1px solid rgba(95,100,114,0.1);
//}

.summary {
  .section-table__add-task--project {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .section-table__export {
    @media screen and (max-width: 1440px) {
      & {
        width: 100% !important;
      }
    }
  }
}

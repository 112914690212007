.page-footer {

	margin-top: 20px;

	display: grid;
	grid-template-columns: 1fr 1fr;

	@include _992 {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	gap: 10px;

	// .page-footer__row-per-page

	&__row-per-page {

		display: flex;
		align-items: center;

		> span {
			padding-right: 10px;

			color: var(--text-color);
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 120%;
		}

		// .page-footer__row-per-page--select

		&--select {
			width: 88px;
		}
	}

	// .page-footer__see-more

	&__see-more {
	}
}
